<template>
  <div>
    <div class="flex-between">
      <div style="display: flex">
        <el-button @click="getList">刷新数据</el-button>
        <el-input
          placeholder="标题内容"
          v-model="search"
          class="input-with-select margin-left-twentyFour"
          @keydown.enter.native="handlePageChange(1)"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handlePageChange(1)"
          ></el-button>
        </el-input>
      </div>
      <el-button icon="el-icon-plus" @click="handleAdd(null)" type="primary"
        >新增标题</el-button
      >
    </div>
    <el-table
      :loading="loading"
      :data="dataList"
      height="calc(100vh - 388px)"
      style="width: 100%; margin: 20px 0"
    >
      <el-table-column prop="id" label="ID" width="100"> </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scope">
          <el-button
            class="button-small"
            type="primary"
            @click="handleAdd(scope.row)"
            >修改</el-button
          >
          <el-button
            class="button-small"
            type="danger"
            @click="handleDelete(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 16px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="15"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 新增、编辑 -->
    <el-dialog
      :title="selectItem ? '修改' : '新增'"
      :visible.sync="showEdit"
      width="380px"
      top="20%"
      center
    >
      <el-input
        v-model="title"
        placeholder="标题"
        style="width: 300px; margin: 0 15px 10px"
      />
      <el-select
        v-if="selectItem"
        v-model="category"
        placeholder="分类"
        style="width: 300px; margin: 0 15px 10px"
      >
        <el-option
          v-for="item in classifyList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEdit = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTitleList,
  editTitle,
  deleteTitle,
  getClassifyList
} from '@/api/classify'

export default {
  name: 'Title',
  props: {
    categoryId: Number
  },
  data () {
    return {
      loading: false,
      search: null,
      page: 1,
      total: 0,
      dataList: [],
      showEdit: false,
      selectItem: null,
      title: null,
      category: null,
      classifyList: null,
      titleId: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => vm.getList())
  },
  watch: {
    categoryId (newVal) {
      if (newVal) {
        this.search = null
        this.handlePageChange(1)
      }
    }
  },
  methods: {
    handlePageChange (page) {
      this.page = page
      this.getList()
    },
    getList () {
      if (!this.categoryId) {
        return false
      }
      this.loading = true
      getTitleList({
        page: this.page,
        pageSize: 15,
        title: this.search,
        categoryId: this.categoryId
      })
        .then((res) => {
          this.dataList = res.list
          this.total = res.total
        })
        .finally(() => (this.loading = false))
    },
    handleAdd (item) {
      this.selectItem = item
      if (item) {
        this.category = this.categoryId
        this.title = item.title
        this.titleId = item.id
      } else {
        this.titleId = null
        this.title = null
        this.category = this.categoryId
      }
      this.showEdit = true
      // this.$prompt("请输入标题", item ? "编辑" : "新增", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   inputType: "text",
      //   inputValue: item ? item.title : "",
      // })
      //   .then(({ value }) => {
      //     if (value) {
      //       editTitle({
      //         id: item ? item.id : null,
      //         title: value,
      //         categoryId: this.categoryId,
      //       }).then(() => {
      //         this.handlePageChange(1);
      //         this.$message.success("保存成功");
      //       });
      //     }
      //   })
      //   .catch(() => {});
    },
    // 提交修改、新增
    handleSubmitEdit () {
      if (!this.title) {
        this.$message.warning('标题不能为空')
        return false
      }
      // console.log({
      //   id: this.titleId || null,
      //   title: this.title,
      //   categoryId: this.category,
      // });
      // return;
      editTitle({
        id: this.titleId,
        title: this.title,
        categoryId: this.category
      })
        .then(() => {
          this.handlePageChange(this.page)
          this.$message.success('保存成功')
        })
        .finally(() => {
          this.showEdit = false
        })
    },
    handleDelete (id) {
      this.$confirm('此操作将永久删除该标题， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteTitle(id).then(() => {
            this.$message.success('删除成功')
            this.handlePageChange(1)
          })
        })
        .catch(() => {})
    }
  },
  created () {
    getClassifyList({
      type: 1
    })
      .then((res) => {
        this.classifyList = res
      })
      .finally(() => {})
  }
}
</script>

<style scoped>
</style>
